/** IMPORTS **/
@import 'functions', 'mixins', '_custom_bootstrap_variables';
@import '_base';


  .contentOne, .contentTwo, .contentThree, .contentFour {
    border: 12px solid #55c7c3;
    border-right: 0;
    border-left: 0;
    border-bottom: 100px solid #55c7c3;
    background-color: #fbfbfb;
    @include media-breakpoint-down(sm){
      border-bottom: 50px solid #55c7c3;
    }
    @include media-breakpoint-down(xs){
      border-bottom: 50px solid #55c7c3;
    }
    //background-color: rgb(85, 199, 195);
   // padding-top: 2rem;
    .innerContainer {
      display: flex;
      flex-direction: column;

      p {
        line-height: 25px;
        margin-top: 3%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        height: 100%;
      }
      @include media-breakpoint-down(sm) {
        margin: 0px auto;
        p {
          font-size: 14px;
        }
        h3 {
          font-size: 16px;
        }
      }

      .landingPage {
        margin: 2rem 1.5rem;
        .loginView {
          .loginViewImage {
            width: 100%;
          }
        }
      }

      .img_container {
        display: flex;
        justify-content: center;
        margin: 15% auto 0;

        &.nonTop {
          margin: 15px;
        }

        .individualPhone {
          width: 35%;
        }
        .mobileExample {
          width: 100%;
          height: 100%;
          margin: 3rem 0;

          &.tablet {
            width: 60%;
            height: 100%;
            @include media-breakpoint-down(md) {
              width: 80%;
              height: 100%;
            }
          }

          @include media-breakpoint-down(sm) {
            width: 100%;
            margin-top: 2rem;
          }
        }
      }

      .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        text-align: left;
        margin: 6rem auto;
        @include media-breakpoint-down(md){
          width: 90%;
          margin: 2rem auto;
        }
        h2{
          font-size: 2rem;
          @include media-breakpoint-down(sm){
            font-size: 1.5rem;
          }
        }
        .role {
          h2{
            color: #55c7c3;
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
          }
        }
        .introContent{
          font-size: 1.125rem;
          font-weight: bold;
          .description{
            font-weight: normal;
          }

        }
      }
      .subContent{
        margin-bottom: 10rem;
        h2{
         font-size:2rem;
         color:#55c7c3;
          @include media-breakpoint-down(sm){
            font-size: 1.5rem;
          }
        }
        h1{
          font-size: 3.5rem;
          @include media-breakpoint-down(sm){
            font-size: 2rem;
          }
        }
        .titleContainer {
          background-image: linear-gradient(to right, indigo, #55C7C3);
          display: flex;
          height: 200px;
          @include media-breakpoint-down(md){
            height: 130px;
          }
          .title {
            display: flex;
            justify-content: center;
            margin: auto;
            .name {
              color: #ffffff;
              margin: 1rem;
            }
          }
        }
        .description{
          display: flex;
          width: 90%;
          margin: auto;
          margin-top: 10rem;
          font-size: 1.125rem;
          &.followSingle, &.single{
            margin-top: 10rem;
            @include media-breakpoint-down(md){
              margin-top: 5rem;
            }
          }
          &.bottomImage{
            flex-direction: column;
            width: 60%;
            height: 100%;
            .verbage{
              margin-bottom: 5rem;
              @include media-breakpoint-down(sm){
                width: auto;
              }
            }
            .verbage, .relatedImage{
              width: initial;
              height: auto;
            }
          }
          &.catalogDesktop{
            margin-top: 10rem;
            flex-direction: column-reverse;
            @include media-breakpoint-down(md){
              margin-top: 7rem;
            }
            .verbage{
              width: 60%;
              @include media-breakpoint-down(md){
                width: 100%;
              }
            }
            .relatedImage{
              justify-content: center;
              margin-top: 5rem;
              width: 100%;
              height: auto;
              @include media-breakpoint-down(md){
                margin-top: 3rem;
                .contentImage{
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          &.imgAndGif{
            .first{
              display: flex;
              flex-direction: column;
              width: 70%;
              height: 100%;
              @include media-breakpoint-down(md){
                width: 100%;
                height: 100%;
              }
              .contentImage{
                width: 100%;
              }
            }
            .second{
              width: 30%;
              height: auto;
              height: 100%;
              @include media-breakpoint-down(md){
                width: 100%;
                height: 100%;
              }
              .contentImage{
                width: 80%;
                height: 100%;
              }
            }
            .mobileGif{
              .contentImage{
                width: 60%;
              }
            }
            &.future{
              @include media-breakpoint-down(md){
                flex-direction: column-reverse;
              }
              .relatedImage{
                justify-content: flex-start;
                @include media-breakpoint-down(md){
                  justify-content: center;
                }
              }
            }
          }
          &.images{
            margin-top: 10rem;
            &.mobileVersion{
              @include media-breakpoint-up(lg){
                display: none;
              }
              @include media-breakpoint-down(md){
                display: flex;
                margin-top: 5rem;
              }
            }
            @include media-breakpoint-down(md){
              margin-top: 5rem;
            }
          }
          &.oneImage{
            margin-top: 10rem;
            &.desktopImage{
              @include media-breakpoint-down(md){
                display: none;
              }
            }
            .relatedImage{
              justify-content: center;
              width: 100%;
            }
            @include media-breakpoint-down(md){
              margin-top: 5rem;
            }
          }
          @include media-breakpoint-down(md){
            margin-top: 5rem;
            flex-direction: column;
            &.left{
              flex-direction: column-reverse;
            }
          }

          .verbage{
            width: 40%;
            margin: auto;
            font-size: 1.2rem;
            @include media-breakpoint-down(md){
              width: 100%;
              margin-bottom: 3rem;
            }
            &.single{
              width: 60%;
              @include media-breakpoint-down(md){
                width: auto;
              }
            }
          }
          .relatedImage{
            display: flex;
            justify-content: flex-end;
            width: 60%;
            height: 100%;
            margin: auto;
            @include media-breakpoint-down(md){
              justify-content: center;
            }
            &.left{
              justify-content: flex-start;
              @include media-breakpoint-down(md){
                justify-content: center;
              }
              &.gifDesktop{
                justify-content: flex-start;
                @include media-breakpoint-down(md){
                  justify-content: center;
                }
                .gifDestopImg{
                  width: 90%;
                  height: 100%;
                  @include media-breakpoint-down(md){
                    width: 100%;
                    height: 100%;
                  }
                }
              }

            }
            @include media-breakpoint-down(md){
              width: auto;
              margin-bottom: 1rem;
            }

            &.gif{
              @include media-breakpoint-down(md){
                justify-content: center;
              }
            }
            .contentImage{
              width: 90%;
              height: 100%;
              @include media-breakpoint-down(md){
                justify-content: center;
                height: 100%;
              }
              &.gif{
                width: 50%;
                @include media-breakpoint-down(md){
                  justify-content: center;
                  height: 100%;
                }
              }

            }
            &.twoImages{
              width: 50%;
              height: 100%;
              @include media-breakpoint-down(md){
                width: 100%;
                justify-content: center;
                height: 100%;
              }

              .longMobile{
                width: 60%;
                height: 100%;
              }
            }
            &.oneImages{
              justify-content: center;
              width: 100%;
              height: 100%;
            }

          }
          .fullImage{
            width: 100%;
            height: 100%;
            .contentImage{
              width: 100%;
              height: 100%;
            }
          }
          #onboardingMock{
            .contentImage{
              width: 90%;
              height: 100%;
            }
          }
        }
      }

      margin: auto;

      .portal {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #b1b1b1;
        font-size: 14px;
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }

        .group {
          display: flex;
          flex-direction: row;
          @include media-breakpoint-down(md) {
            flex-direction: column;
            margin-bottom: 3%;
            &.first {
              margin-bottom: 1%;
            }
          }

          .info {
            flex-grow: 1;
            flex-basis: 0;

            .details {
              margin-bottom: 3%;

              .title {
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 2%;
                color: #38393b;
              }

              .description {
                line-height: 25px;
                font-size: 1.125rem;
              }
            }
          }
        }
      }

      .mobilePhones {
        display: flex;
        justify-content: center;
        @include media-breakpoint-down(md) {
          flex-direction: column;
        }

        .phoneExamples {
          width: 80%;
          height: 100%;
          @include media-breakpoint-down(md) {
            width: 70%;
            height: 100%;
          }
        }
      }
    }
  }
  